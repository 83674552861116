export default {
  NODE_ENV: "production",

  // firebase
  FIREBASE_API_KEY: "AIzaSyB66KkpB6rnQza-4g1eV1iY7qGEPBGvQQY",
  FIREBASE_AUTH_DOMAIN: "crewmen-prod.firebaseapp.com",
  FIREBASE_PROJECT_ID: "crewmen-prod",
  FIREBASE_STORAGE_BUCKET: "crewmen-prod.appspot.com",
  FIREBASE_MESSAGING_SENDER_ID: "218550442467",
  FIREBASE_APP_ID: "1:218550442467:web:38f87cbecc0859c46228d6",
  FIREBASE_MEASUREMENT_ID: "G-C0H7162FWE",

  // google map
  GOOGLE_PLACE_URL:
    "https://maps.googleapis.com/maps/api/place/autocomplete/json",
  GOOGLE_PLACE_API_KEY: "AIzaSyB66KkpB6rnQza-4g1eV1iY7qGEPBGvQQY",

  // collections
  COLLECTION_USER: "users",
  COLLECTION_COVER_IMAGE: "coverImages",
  COLLECTION_EVENT: "events",
  COLLECTION_EVENT_MESSAGE: "eventMessages",
  COLLECTION_INTEREST: "interests",
  COLLECTION_NOTIFICATION: "notifications",

  // end points
  BASE_URL: "https://crewmen-prod.ts.r.appspot.com/api/v1",
  EP_RESET_PASSWORD: "/user/resetPassword",
  EP_MANAGE_EVENT_DETAILS: "/event/manageEventDetails",
  EP_MANAGE_EVENT_INTERESTS: "/event/manageEventInterests",
  EP_GET_EVENTS: "/event/getEventsAP",
  EP_GET_EVENT_DETAILS: "/event/getEventDetailsAP",
  EP_DELETE_EVENT: "/event/deleteEventAP",
  EP_MANAGE_EVENT_FAQ: "/event/manageEventFaqAP",
  EP_DELETE_EVENT_FAQ: "/event/deleteEventFaqAP",
  EP_SEEN_EVENT_CHAT_MESSAGE: "/event/seenEventChatMessageAP",
  EP_SEND_EVENT_CHAT_MESSAGE: "/event/sendEventChatMessageAP",
  EP_DUPLICATE_EVENT: "/event/duplicateEventAP",
  EP_GET_USERS: "/user/getUsersAP",
  EP_UPDATE_PROFILE_VERIFICATION: "/user/updateProfileVerificationAP",
  EP_LOGOUT_ALL_SESSIONS: "/user/logoutAllSessionsAP",
  EP_BLOCK_USER_ACCOUNT: "/user/blockUserAccountAP",
  EP_UNBLOCK_USER_ACCOUNT: "/user/unblockUserAccountAP",
  EP_SEEN_NOTIFICATION: "/notification/seenAdminNotificationAP",
  EP_VIEWED_NOTIFICATION: "/notification/viewedAdminNotificationAP",
  EP_GET_CALLOUTS: "/callout/getCalloutsAP",
};
